import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, firstValueFrom, forkJoin, lastValueFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Lottery } from '../shared/models/lottery.model';
import { Payment } from '../shared/models/payment.model';
import { User } from '../shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  private apiUrl = 'https://www.ganatetuauto.com/api.php'; // Replace with your Hostinger domain
  // private apiUrl = 'http://localhost/api.php';
  // Subject to notify subscribers about changes in lotteries
  private lotteriesSubject = new Subject<Lottery[]>();
  private paymentsSubject = new Subject<Payment[]>();
  private usersSubject = new Subject<User[]>();

  constructor(
    private http: HttpClient,
    ) {}

  private getData(table: string, method: string): Observable<any> {
    const url = `${this.apiUrl}?table=${table}&method=${method}`;
    return this.http.get(url);
  }

  getMaintenanceMode(): Observable<boolean> {
    const url = `${this.apiUrl}?table=settings&method=maintenanceMode`;
    return this.http.get<boolean>(url);
  }

  private deleteRecord(table: string, method: string, id: number) {
    const url = `${this.apiUrl}?table=${table}&method=${method}&id=${id}`;
    return this.http.get(url);
  }

  private notifyLotteriesChange() {
    // Fetch lotteries after an insert or delete and notify subscribers
    this.getData('lottery', 'getAll').subscribe(result => {
      const lotteries = result.data.map((lottery: any) => new Lottery().deserialize(lottery));
      this.lotteriesSubject.next(lotteries);
    });
  }

  insertData(table: string, data: any): Observable<any> {
    const url = `${this.apiUrl}`;
    const formData = new FormData();
    formData.append('table', table);
    formData.append('method', 'insert');
    formData.append('data', JSON.stringify(data));

    return this.http.post(url, formData);
  }

  public getAllLotteries(): Observable<Lottery[]> {
    this.notifyLotteriesChange();
    return this.lotteriesSubject.asObservable();
  }

  public getLotteries() {
    let lotteries: Lottery[] = [];
    this.getData('lottery', 'getAll').subscribe(result => {
      result.data.forEach((lottery: any) => {
        lotteries.push(new Lottery().deserialize(lottery));
      });
    });
    return lotteries;
  }

  getLotteryById(id: number): Observable<Lottery | null> {
    const url = `${this.apiUrl}?table=lottery&method=getById&id=${id}`;
    return this.http.get(url).pipe(
      map((data: any) => (data ? new Lottery().deserialize(data) : null))
    );
  }

  public deleteLottery(lotteryId: number) {
    firstValueFrom(this.deleteRecord('lottery', 'delete', lotteryId)).then(result => {
      // After successful delete, fetch all lotteries and notify subscribers
      this.notifyLotteriesChange();
    });
  }

  public deletePayment(paymentId: number) {
    firstValueFrom(this.deleteRecord('payments', 'delete', paymentId)).then(result => {
      // After successful delete, fetch all lotteries and notify subscribers
      this.notifyPaymentsChange();
    });
  }

  public updatePayment(paymentId: number, data: any) {
    data.tickets_id = JSON.stringify(data.tickets_id);
    data.datetime = this.formatMySQLDatetime(data.datetime);
    data.attachment = data.attachmentUrl;

    const url = `${this.apiUrl}`;
    const formData = new FormData();
    formData.append('table', 'payments');
    formData.append('method', 'update');
    formData.append('id', paymentId.toString());
    formData.append('data', JSON.stringify(data));

    this.http.post(url, formData).subscribe(result => {
      this.notifyPaymentsChange();
    });

    
  }

  public async createPayment(data: any) {
    data.tickets_id = JSON.stringify(data.tickets_id);
    data.datetime = this.formatMySQLDatetime(data.datetime);
    data.attachment = data.attachmentUrl;
    
    try {
      return await firstValueFrom(this.insertData('payments', data));
      // Assuming you want to return something on success
      // return { success: true };
  } catch (error) {
      console.error(error);
      // Handle the error, you might want to throw or return an error object
      throw error;
  }
  }

  getPaymentById(id: number): Observable<Payment | null> {
    const url = `${this.apiUrl}?table=payments&method=getById&id=${id}`;
    return this.http.get(url).pipe(
      map((data: any) => (data ? new Payment().deserialize(data) : null))
    );
  }

  getTicketsByClient(fullname: string, lotteryId: number) {
    const url = `${this.apiUrl}?table=payments&method=getClientTickets&fullname=${fullname}&lottery_id=${lotteryId}`;
    return this.http.get(url).pipe(
      map((data: any) => (data ? data : null))
    );
  }

  getTotalTickets() {
    return this.getData('tickets', 'getTotal');
  }

  public getPayments() {
    let payments: Payment[] = [];
    this.getData('payments', 'getAll').subscribe(result => {
      result.data.forEach((payment: any) => {
        payments.push(new Payment().deserialize(payment));
      });
    });
    return payments;
  }

  public getAllPayments(): Observable<Payment[]> {
    this.notifyPaymentsChange();
    return this.paymentsSubject.asObservable();
  }

  private notifyPaymentsChange() {
    // Fetch lotteries after an insert or delete and notify subscribers
    this.getData('payments', 'getAll').subscribe(result => {
      const payments = result.data.map((payments: any) => new Payment().deserialize(payments));
      this.paymentsSubject.next(payments);
    });
  }

  uploadImage(formData: FormData): Observable<any> {
    const url = `${this.apiUrl}`;
    formData.append('table', 'lottery');
    formData.append('method', 'uploadImage');
    return this.http.post(url, formData);
  }

  uploadAttachment(formData: FormData): Observable<any> {
    const url = `${this.apiUrl}`;
    formData.append('table', 'payment');
    formData.append('method', 'uploadAttachment');
    return this.http.post(url, formData);
  }

  private formatMySQLDatetime(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  private notifyUsersChange() {
    // Fetch lotteries after an insert or delete and notify subscribers
    this.getData('users', 'getAll').subscribe(result => {
      const users = result.data.map((users: any) => new User().deserialize(users));
      this.usersSubject.next(users);
    });
  }

  public deleteUser(userId: number) {
    firstValueFrom(this.deleteRecord('users', 'delete', userId)).then(result => {
      // After successful delete, fetch all lotteries and notify subscribers
      this.notifyUsersChange();
    });
  }

  public updateUser(userId: number, data: any) {
    const url = `${this.apiUrl}`;
    const formData = new FormData();
    formData.append('table', 'users');
    formData.append('method', 'update');
    formData.append('id', userId.toString());
    formData.append('data', JSON.stringify(data));

    this.http.post(url, formData).subscribe(result => {
      this.notifyUsersChange();
    });

    
  }

  public createUser(data: any) {
    this.insertData('users', data).subscribe(result => {
      this.notifyUsersChange();
    });
  }

  public getAllUsers(): Observable<User[]> {
    return this.usersSubject.asObservable();
  }

  public getUsers() {
    let users: User[] = [];
    this.getData('users', 'getAll').subscribe(result => {
      result.data.forEach((payment: any) => {
        users.push(new User().deserialize(payment));
      });
    });
    return users;
  }

  getUserById(id: number): Observable<User | null> {
    const url = `${this.apiUrl}?table=users&method=getById&id=${id}`;
    return this.http.get(url).pipe(
      map((data: any) => (data ? new User().deserialize(data) : null))
    );
  }

  getAllSettings(): Observable<any | null> {
    const url = `${this.apiUrl}?table=settings&method=getAll`;
    return this.http.get(url);
  }
}
