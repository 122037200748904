import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
  selector: 'app-page-terms',
  templateUrl: './page-terms.component.html',
  styleUrls: ['./page-terms.component.css']
})

/***
 * Page terms Component
 */
export class PageTermsComponent implements OnInit {
  public contactNumber: string = '';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true
  constructor(
    private database: DatabaseService,
  ) {
    this.database.getAllSettings().subscribe((result:any) => {
      for (const item of result.data) {
        if (item.code == 'whatsapp') {
          this.contactNumber = item.value;
        }
      }
    });
  }

  ngOnInit(): void {
  }

}
