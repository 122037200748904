import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Lottery } from 'src/app/shared/models/lottery.model';

@Component({
  selector: 'app-lottery-card',
  templateUrl: './lottery-card.component.html',
  styleUrls: ['./lottery-card.component.css']
})
export class LotteryCardComponent implements OnChanges {
  @Input() lottery: Lottery;
  public image: string = "assets/images/no-image.png";

  ngOnChanges(changes: SimpleChanges) {
      if (changes['lottery'] && this.lottery?.image) {
        try {
          this.image = JSON.parse(this.lottery.image)[0];
        } catch (error) {
          console.error("Error parsing image:", error);
        }
      }
    } 
}
