import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatabaseService } from './database.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private apiUrl = 'https://www.ganatetuauto.com/mailapi.php'; // Update with the correct path

  constructor(
    private http: HttpClient,
    private database: DatabaseService,
  ) { }

  sendEmail(emailData: { to: string; subject: string; text: string }): any {
    console.log('sending email');
    this.http.post(this.apiUrl, emailData).subscribe((data) => {
      console.log('data', data);
    });
  }

  sendApprovedPaymentEmail(emailTo: string, lotteryDate: string, tickets: any[]) {

    let ticketsHtml: string = '';

    tickets.forEach(ticket => {
      ticketsHtml += `<span style="display: inline-block; width: 80px; height: 80px; background-color: #1e0e4b; color: #ffffff; text-align: center; line-height: 80px; font-size: 18px; border-radius: 5px; margin: 5px;">${ticket.number}</span>`;
    });

    let emailTemplate: string =
      `
    <!DOCTYPE html>
    <html lang="en">
    
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Gánate tu Auto</title>
    </head>
    
    <body style="font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; background-color: #FFFFFF; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    
      <table width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color: #FFFFFF;">
        <tr>
          <td align="center">
            <img src="https://ganatetuauto.com/assets/images/logo-circular-negro.png" alt="Gánate tu Auto Logo" width="100">
            <h1 style="color: #1e0e4b; font-size: 24px; font-weight: 700;">¡Gánate tu Auto!</h1>
            <p style="color: #444a5b; font-size: 16px;">Fecha del Sorteo: ${lotteryDate}</p>
            <p style="color: #444a5b; font-size: 16px;">
              Gracias por tu compra. A continuación, te presentamos los números de los boletos seleccionados de forma aleatoria:
            </p>
            <p style="text-align: center;">
              ${ticketsHtml}
            </p>
            <p>¡Síguenos en @ganatetuauto!</p>
          </td>
        </tr>
      </table>
    
    </body>
    
    </html>
    `;

    const emailData = {
      to: emailTo,
      subject: 'Gánatetuauto.com - ¡Tus boletos!',
      text: emailTemplate,
    };

    this.sendEmail(emailData);

  }

  sendPaymentReceivedEmail(emailTo: string, lotteryDate: string, paymentId: number, lottery: any) {
    let rewardsList: string = '';

    const rewards = JSON.parse(lottery.rewards);

    rewards.forEach((reward: any) => {
      rewardsList += `∙ ${reward.label}: ${reward.value}<br>`;
    });


    let emailTemplate: string =
      `
    <!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Gánate tu Auto</title>
</head>

<body style="font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; background-color: #FFFFFF; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">

  <table width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color: #FFFFFF;">
    <tr>
      <td align="center">
        <img src="https://ganatetuauto.com/assets/images/logo-circular-negro.png" alt="Gánate tu Auto Logo" width="100">
        <h1 style="color: #1e0e4b; font-size: 24px; font-weight: 700;">¡Gánate tu Auto!</h1>
      </td>
    </tr>
    <tr>
      <td align="center">
        <p style="color: #444a5b; font-size: 16px;"><b>Fecha del Sorteo: </b> ${lotteryDate}. <b> Participas por: </b> </p>
        <p style="color: #444a5b; font-size: 16px;">
          ${rewardsList}
        </p>
        <p style="color: #444a5b; font-size: 16px;">
        Gracias por tu compra <b>YA ESTAS PARTICIPANDO!!</b> Puedes revisar nuestros otros sorteos o seguir comprando para obtener más probabilidades de ganar.
        </p>
        <p style="color: #444a5b; font-size: 16px;">
        Dentro de las siguientes <b>24 a 48 horas</b>, recibirás un correo referente a tu compra y estado del pago realizado. Una vez validado el mismo, recibirás el correo con los números de ticket seleccionados de forma aleatoria.
        </p>
        <p style="color: #444a5b; font-size: 16px;">
        Nuestro equipo está encargado de ofrecerte el mejor de los servicios y apoyo posible, por ello agradecemos tu consideración y paciencia mientras procesamos y validamos tu pago.
        </p>
      </td>
    </tr>
    <tr>
      <td align="center">
        <h3 style="color: #444a5b;"><b>Número de compra (No es el número participante):</b> # ${paymentId}</h3>
      </td>
    </tr>
    <tr>
      <td align="center">
        <p style="color: #444a5b; font-size: 16px;">Instagram: ganatetuauto - Tiktok: @ganatetuauto</p>
      </td>
    </tr>
  </table>

</body>

</html>

    `;

    const emailData = {
      to: emailTo,
      subject: 'Gánatetuauto.com - ¡Compra realizada!',
      text: emailTemplate,
    };

    this.sendEmail(emailData);
  }

  async sendPaymentReceivedAdmin(lottery: any, paymentData: any) {
    const date = new Date(lottery!.datetime);
    const lotteryDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
    const settings = await firstValueFrom(this.database.getAllSettings());
    let emailTo = settings.data.find((setting: any) => setting.code == 'notificationEmail').value;
    let emailTemplate: string =
      `
    <!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Gánate tu Auto</title>
</head>

<body style="font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; background-color: #FFFFFF; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">

  <table width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color: #FFFFFF;">
    <tr>
      <td align="center">
        <img src="https://ganatetuauto.com/assets/images/logo-circular-negro.png" alt="Gánate tu Auto Logo" width="100">
        <h1 style="color: #1e0e4b; font-size: 24px; font-weight: 700;">¡Gánate tu Auto!</h1>
        
        <p style="color: #444a5b; font-size: 16px;">
          Has recibido un nuevo pago en la plataforma. Por favor, revisa la información del mismo en el panel de administración.
        </p>
        <h3>Información del pago:</h3>

          <p style="color: #444a5b; font-size: 16px;">
            Sorteo: ${lottery.name}
           <br>Fecha del sorteo: ${lottery!.datetime}
           <br>Nombre: ${paymentData.fullname}
           <br>Cédula: ${paymentData.id_number}
           <br>Correo: ${paymentData.email}
           <br>Teléfono: ${paymentData.phoneNumber}
         </p>

        <p>¡Síguenos en @ganatetuauto!</p>
      </td>
    </tr>
  </table>

</body>

</html>

    `;

    const emailData = {
      to: emailTo,
      subject: 'Gánatetuauto.com - ¡Nuevo pago recibido!',
      text: emailTemplate,
    };

    if (emailTo.length > 0) {
      this.sendEmail(emailData);
    }
  }
}
